export const curses = [
  {
    price: '2930',
    name: 'Базовый',
    countOfLessons: '6',
    description: 'Отлично для начала',
  },
  {
    price: '5720',
    name: 'Стандартный',
    countOfLessons: '12',
    description: 'То что нужно для вашего ребенка',
  },
  {
    price: '10520',
    name: 'Продвинутый',
    countOfLessons: '24',
    description: 'Для полной уверености',
  },
];
