export const curses = [
  {
    price: '2930',
    name: 'Basic',
    countOfLessons: '6',
    description: 'Great for a start',
  },
  {
    price: '5720',
    name: 'Standart',
    countOfLessons: '12',
    description: 'What you need for your child',
  },
  {
    price: '10520',
    name: 'Advanced',
    countOfLessons: '24',
    description: 'For complete confidence',
  },
];
